<template>
    <div>
        <div v-if="isAxiosReady" class="loading-screen">
            <router-view />
        </div>
        <div v-else>
            Loading...
        </div>
    </div>
</template>

<script>
    import siriusHub from '../src/siriushub'
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'App',
        data() {
            return {
                chatId: ''
            }
        },
        methods: {
            ...mapActions(['setSelectedChatId', 'setSelectedChannelId', 'setSelectedAccountsId', 'setSelectedCustomerId', 'setTenantId', 'setStaffId', 'setTenantUrl', 'setBranchId', 'setToken', 'axiosInitialize']),

            stopSignalR() {
                siriusHub.stop();
            },
            isAllowedOrigin(origin) {
                const allowedDomain = process.env.VUE_APP_STELLA_APP_BASE_URL;

                try {
                    const url = new URL(origin);
                    const domain = url.hostname.replace(/^www\./, '');
                    return domain.endsWith(allowedDomain);
                } catch (e) {
                    return false;
                }
            }
        },
        computed: {
            ...mapGetters(['isAxiosReady', 'isIframe'])
        },
        watch: {
            chatId(newChatId) {
                this.setSelectedChatId(newChatId);
            }
        },
        created() {
            const urlParams = new URLSearchParams(window.location.search);
            this.chatId = urlParams.get('chatId');
        },
        mounted() {
            var self = this;

            if (this.isIframe) {
                window.addEventListener("message", function (event) {
                    if (!self.isAllowedOrigin(event.origin))
                        return;

                    if (event.data.action == "initialize") {
                        self.axiosInitialize(event.data)
                            .then(() => {

                                if (event.data.customerId) {
                                    self.setSelectedChatId(event.data.chatId);
                                    self.setSelectedChannelId(event.data.channelId);
                                    self.setSelectedAccountsId(event.data.senderId);
                                    self.setSelectedCustomerId(event.data.customerId);
                                }
                            });
                    }

                }, false);
            }
        }
    }
</script>
