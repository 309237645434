import {
    HubConnectionBuilder,
    LogLevel
} from "@microsoft/signalr";
import store from '../src/store/vuex'

class siriusHub {
    init(url, tenantId, staffId) {

        if (url && url.slice(-1) !== '/') {
            url += '/';
        }

        this.client = new HubConnectionBuilder()
            .withUrl(`${url}stellaHub?tenantId=${tenantId}&staffId=${staffId}`, { withCredentials: false })
            .configureLogging(LogLevel.Warning)
            .withAutomaticReconnect([0, 3000, 5000, 7000, 10000, 15000])
            .build();

        this.client.onreconnecting((error) => {
            store.dispatch("setIsHubConnected", false);
            console.error(error);
        });

        this.client.onreconnected(() => {
            store.dispatch("setIsHubConnected", true);
        });

        this.client.onclose((error) => {
            store.dispatch("setIsHubConnected", false);
            console.error(error);
        });

        this.client.on("onWhatsappMessageStatusChanged", function (msg) {
            if (msg)
                store.dispatch("setStatusChange", msg);
        });

        this.client.on("onWhatsappClientEventHandled", function (msg) {
            if (msg)
                store.dispatch("setClientEventHandled", msg);
        });

        this.client.on("onChatArchiveStateChanged", function (msg) {
            if (msg)
                store.dispatch("setChatArchiveStateChanged", msg);
        });

        this.client.on("onWhatsappMessageReceived", function (msg) {
            if (msg)
                store.dispatch("setNewMessage", msg);
        });

        this.client.on("onWhatsappMessageSent", function (msg) {
            if (msg)
                store.dispatch("setNewMessage", msg);
        });

        this.client.on("onWhatsappChatHistoryMessageIncluded", function (msg) {
            if (msg)
                store.dispatch("setNewlyChatHistoryMessage", msg);
        });

        this.client.on("onWhatsappAutoRespondMessageSent", function (msg) {
            if (msg)
                store.dispatch("setNewMessage", msg);
        });

        this.client.on("onChatCustomerIdUpdated", function (msg) {
            if (msg)
                store.dispatch("setUpdatedChatCustomerId", msg);
        });

        this.client.on("onChatHistoryCompleted", function (msg) {
            if (msg)
                store.dispatch("setChatHistoryCompleted", msg);
        });
        
        this.client.on("onRefreshTokenChanged", function (refreshTokenObject) {
            const windowCurrentBranchId = store.getters.branchId;
            const tokenBranchId = refreshTokenObject.branchId;

            if (refreshTokenObject.tokenObject) {
                if (windowCurrentBranchId == tokenBranchId) {
                    const newJwtToken = refreshTokenObject.tokenObject.token;
                    store.dispatch("setToken", newJwtToken);
                }
            }
        });
    }

    start() {
        const self = this;

        if (this.client.state == 'Disconnected') {
            this.client.start()
                .then(() => {
                    store.dispatch("setIsHubConnected", true);
                })
                .catch(() => {
                    store.dispatch("setIsHubConnected", false);
                    setTimeout(function () { self.start(); }, 1500);
                });
        }
    }

    stop() {
        if (this.client) {
            if (this.client.state != 'Disconnected') {
                this.client.stop();
            }
            store.dispatch("setIsHubConnected", false);
        }
    }
}

export default new siriusHub();